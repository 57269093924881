import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import "./scss/style.scss";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const ERPMainLayout = React.lazy(() => import("./containers/ERPMainLayout"));
const HRMainLayout = React.lazy(() => import("./containers/HRMainLayout"));
const PlanManagementMainLayout = React.lazy(() =>
  import("./containers/PlanManagementMainLayout")
);

// for recruit domain
let recruitDomain = process.env.REACT_APP_RECRUIT_DOMAIN;

// Pages

// const Login = React.lazy(() => import("./views/erp/login/LoginIndex"));
const Signin = React.lazy(() => import("./views/sign-in/SignInIndex"));
const Auth = React.lazy(() => import("./views/sign-in/Auth"));
const ERPAuth = React.lazy(() => import("./views/sign-in/AuthForm"));
const Authorization = React.lazy(() => import("./views/brycen-common/authorization-flow/Authorization"));


const ForgotPassword = React.lazy(() =>
  import("./views/erp/forgot-password/ForgotPasswordIndex")
);
const ResetPassword = React.lazy(() =>
  import("./views/erp/reset-password/ResetPasswordIndex")
);

const NoPermission = React.lazy(() =>
  import("./views/brycen-common/error-page/NoPermission")
);

const NotRegister = React.lazy(() =>
  import("./views/brycen-common/error-page/NotRegister")
);

const Page404 = React.lazy(() =>
  import("./views/brycen-common/error-page/Page404")
);
// const Page500 = React.lazy(() => import("./views/brycen-common/error-page/Page500"));
const Page403 = React.lazy(() =>
  import("./views/brycen-common/error-page/Page403")
);
const InvalidURL = React.lazy(() =>
  import("./views/brycen-common/error-page/InvalidURL")
);

const SummaryChart = React.lazy(() => import('./views/hr/employee-management/summary-chart/SummaryChartIndex'));

const App = () => {
  //use customize customer name
  let customer_name = window.location.href.split("/")[3];
  let project_name = window.location.href.split("/")[4];
  const loginEvt = useSelector((state) => state.login);

  if (customer_name === "" || customer_name === null) {
    customer_name = "demo";
  }

  let lastUrl = "";

  if (window.location.href) {
    lastUrl = window.location.href.split("/").pop();
  }

  // useEffect(()=>{
  //   if (window.localStorage.getItem(`${customer_name}_TOKEN`) !== null && window.localStorage.getItem(`${customer_name}_REFRESH_TOKEN`) !== null) {
  //     alert("token have")
  //   }else{
  //     alert("token no have")
  //   }
  // })

  useEffect(() => {
    window.addEventListener("storage", (event) => {
      if (localStorage.getItem(`${customer_name}_SIGN_OUT`) && window.location.href.split("/").pop() !== "sign-in") {
        window.location.href = `/${customer_name}/sign-in`;
        localStorage.removeItem(`${customer_name}_SIGN_OUT`);
      }
    });
  }, []);

  useEffect(() => {
    if (loginEvt && window.location.href.split("/").pop() === "sign-in") {
      window.location.href = `/${customer_name}/erp/Dashboard`;
    }
  }, [loginEvt]);


  useEffect(() => {
    document.addEventListener('click', (e) => {
      let name = e.target.innerText;
      if (name == 'Recruitment Management' || name == 'အလုပ်လျှောက်လွှာ စီမံခန့်ခွဲမှု') {
        if (e.target.getAttribute("route") != null) {
          let route = e.target.getAttribute("route").split("/")[3];
          if (route != "NoPermissionPackage") {
            e.preventDefault();
            e.stopPropagation();
            window.open(`${recruitDomain}/${customer_name}/recruit/auth`, '_blank')
          }
        }

      }
    })
  }, []);


  return (
    <BrowserRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route
            exact
            path={`/${customer_name}/sign-in`}
            name="Sign In"
            render={(props) => <Signin {...props} />}
          />
          {/* <Route
              exact
              path={`/${customer_name}/erp/Login`}
              name="Login Page"
              render={(props) => <Login {...props} />}
            /> */}
          <Route
            exact
            path={`/${customer_name}/authorization`}
            name="Auth"
            render={(props) => <Authorization {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/erp/auth`}
            name="Auth"
            render={(props) => <ERPAuth {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/auth`}
            name="Auth"
            render={(props) => <ERPAuth {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/plan/auth`}
            name="Auth"
            render={(props) => <ERPAuth {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/expense/auth`}
            name="Auth"
            render={(props) => <ERPAuth {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/loan/auth`}
            name="Auth"
            render={(props) => <ERPAuth {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/manage-your-storage/auth`}
            name="Auth"
            render={(props) => <ERPAuth {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/auth`}
            name="Auth"
            render={(props) => <Auth {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/recruit/auth`}
            name="Recruit Dashboard"
            render={(props) => window.location.assign(`${recruitDomain}/${customer_name}/recruit/auth`)}
          />
          {/* <Route
              exact
              path={`/${customer_name}/${project_name}/404`}
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            /> */}
          <Route
            exact
            path={`/${customer_name}/${project_name}/no-permission`}
            name="No Permission"
            render={(props) => <NoPermission {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/${project_name}/not-register`}
            name="Not Register"
            render={(props) => <NotRegister {...props} />}
          />
          {/* <Route
              exact
              path={`/${customer_name}/${project_name}/500`}
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            /> */}
          <Route
            exact
            path={`/${customer_name}/403`}
            name="Page 403"
            render={(props) => <Page403 {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/invalid-url`}
            name="URL State Error Page"
            render={(props) => <InvalidURL {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/erp/dashboard`}
            name="Dashboard"
            render={(props) => <ERPMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/erp/employee-management/employee-list`}
            name="Employee List"
            render={(props) => <ERPMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/erp/employee-management/employee-profile`}
            name="Employee Profile"
            render={(props) => <ERPMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/erp/employee-management/employee-registration`}
            name="Employee Registration"
            render={(props) => <ERPMainLayout {...props} />}
          />
          {/* <Route
              exact
              path={`/${customer_name}/erp/employee-management/log-history-list`}
              name="Log History List"
              render={(props) => <ERPMainLayout {...props} />}
            /> */}
          <Route
            exact
            path={`/${customer_name}/erp/employee-management/employee-department-position-history`}
            name="Employee Department Position History"
            render={(props) => <ERPMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/erp/employee-management/employee-type-history`}
            name="Employee Type History"
            render={(props) => <ERPMainLayout {...props} />}
          />
          <Route
            path={`/${customer_name}/erp/department-&-position-management/department-list`}
            name="Department List"
            render={(props) => <ERPMainLayout {...props} />}
          />
          <Route
            path={`/${customer_name}/erp/department-&-position-management/department-&-position-list`}
            name="Department & Position List"
            render={(props) => <ERPMainLayout {...props} />}
          />
          <Route
            path={`/${customer_name}/erp/department-&-position-management/department-register`}
            name="Department Register"
            render={(props) => <ERPMainLayout {...props} />}
          />
          <Route
            path={`/${customer_name}/erp/department-&-position-management/position-register`}
            name="Position Register"
            render={(props) => <ERPMainLayout {...props} />}
          />
          <Route
            path={`/${customer_name}/erp/department-&-position-management/position-list`}
            name="Position List"
            render={(props) => <ERPMainLayout {...props} />}
          />
          <Route
            path={`/${customer_name}/erp/department-&-position-management/employee-assign-department-&-position`}
            name="Employee Assign Department & Position"
            render={(props) => <ERPMainLayout {...props} />}
          />
          <Route
            path={`/${customer_name}/erp/role-and-permission-management/role-register`}
            name="Role Register"
            render={(props) => <ERPMainLayout {...props} />}
          />
          <Route
            path={`/${customer_name}/erp/role-and-permission-management/role-and-permission-list`}
            name="Role and Permission Management"
            render={(props) => <ERPMainLayout {...props} />}
          />
          <Route
            path={`/${customer_name}/erp/role-and-permission-management/user-role-and-permission-register`}
            name="User Role and Permission Register"
            render={(props) => <ERPMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/erp/ForgotPassword`}
            name="Forgot Password"
            render={(props) => <ForgotPassword {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/erp/ResetPassword`}
            name="Reset Password"
            render={(props) => <ResetPassword {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/recruit/NoPermissionPackage`}
            name="No Permission Package"
            render={(props) => <ERPMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/NoPermissionPackage`}
            name="No Permission Package"
            render={(props) => <ERPMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/expense/NoPermissionPackage`}
            name="No Permission Package"
            render={(props) => <ERPMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/plan/NoPermissionPackage`}
            name="No Permission Package"
            render={(props) => <ERPMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/dashboard`}
            name="HR Management"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/dashboard/announcement-register`}
            name="Announcement Registration"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/profile/employee-information-detail`}
            name="Employee Information Detail"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/setting/payroll-rule-setup`}
            name="Payroll Rule Setup"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/setting/payroll-calculation-method-setup`}
            name="Payroll Calculation Method Setup"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/setting/payroll-calculation-list`}
            name="Payroll Calculation List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/setting/ssb-calculation-method-setup`}
            name="SSB Calculation Method Setup"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/setting/ssb-calculation-list`}
            name="SSB Calculation List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/setting/perfect-attendance-setup`}
            name="Perfect Attendance Setup"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/setting/perfect-attendance-list`}
            name="Perfect Attendance List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/setting/company-leave-setting`}
            name="Company Leave Setting"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/setting/shift-normal-rule-register`}
            name="Shift Normal Rule Register"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/setting/shift-normal-rule-list`}
            name="Shift Normal Rule List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/setting/allowance-register`}
            name="Allowance Register"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/setting/allowance-list`}
            name="Allowance List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/setting/suballowance-register-list`}
            name="Suballowance Register List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/setting/employee-allowance-registration`}
            name="Employee Allowance Registration"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/setting/employee-allowance-list`}
            name="Employee Allowance List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/setting/deduction-register`}
            name="Deduction Register"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/setting/deduction-list`}
            name="Deduction List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/setting/employee-deduction-setting`}
            name="Employee Deduction Setting"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/setting/employee-deduction-setting-list`}
            name="Employee Deduction Setting List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/setting/overtime-rate-setting`}
            name="Overtime Rate Setting"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/setting/overtime-rate-list`}
            name="Overtime Rate List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/setting/employee-overtime-registration`}
            name="Employee Overtime Registration"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/setting/employee-overtime-list`}
            name="Employee Overtime List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/setting/overtime-notification-setup`}
            name="Overtime Notification Setup"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/setting/overtime-notification-list`}
            name="Overtime Notification List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/setting/bonus-register`}
            name="Bonus Register"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/setting/bonus-rate-list`}
            name="Bonus Rate List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/setting/basic-salary-register`}
            name="Basic Salary Register"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/setting/basic-salary-list`}
            name="Basic Salary List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/setting/role-and-permission-registration`}
            name="Role And Permission Registration"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/setting/salary-transfer-setting-by-group`}
            name="Salary Rransfer Setting By Group"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/setting/salary-transfer-setting-list`}
            name="Salary Transfer Setting List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/setting/employee-role-registration`}
            name="Employee Role Registration"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/setting/employee-late-early-deduction-list`}
            name="Employee Deduction List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/setting/employee-late-early-deduction`}
            name="Employee Deduction Registration"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/setting/choose-approver-setting`}
            name="Choose Approver Setting"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/setting/snack-registration`}
            name="Snack Registration"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/setting/snack-registration-list`}
            name="Snack Registration List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/setting/employee-leave-cycle-list`}
            name="Employee Leave Cycle List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/setting/employee-leave-cycle-setting`}
            name="Employee Leave Cycle Setting"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/setting/grade-registration`}
            name="Employee Grade Registraton List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/setting/deduction-request-list`}
            name="Deduction Request List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/setting/employee-deduction-request`}
            name="Employee Deduction Request"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/employee-management/salary-calculate-setting`}
            name="Salary Calculate Setting"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/employee-management/family-member-register-list`}
            name="Family Member Register List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/employee-management/employee-leave-setting`}
            name="Employee Leave Setting"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/employee-management/salary-transfer-setting`}
            name="Salary Transfer Setting"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/employee-management/employee-list`}
            name="Employee List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/employee-management/employee-personal`}
            name="Employee Personal"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/employee-management/employee-data-entry`}
            name="Employee Data Entry"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/employee-management/approver-register`}
            name="Approver Register"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/employee-management/approver-list`}
            name="Approver List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/employee-management/log-history-list`}
            name="Log History List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/employee-management/salary-list`}
            name="Salary List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/employee-management/warning-registration`}
            name="Warning Registration"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/employee-management/warning-registration-list`}
            name="Warning Registration List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/employee-management/organization-chart`}
            name="Organization Chart"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/employee-management/employee-guide-rule`}
            name="Employee Guide Rule"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/employee-management/employee-history-list`}
            name="Employee History List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/employee-management/create-contract-template`}
            name="Create Contract Template"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/employee-management/probation-registration`}
            name="Probation Period Registration"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/employee-management/probation-registration-list`}
            name="Probation Period Registration List"
            render={(props) => <HRMainLayout {...props} />}
          />

          {/* <Route
            exact
            path={`/${customer_name}/hr/employee-management/summary-chart`}
            name="Summary Chart"
            render={(props) => <HRMainLayout {...props} />}
          /> */}
          <Route
            exact
            path={`/${customer_name}/summary-chart`}
            name="Summary Chart"
            render={(props) => <SummaryChart {...props} />}
          />

          <Route
            exact
            path={`/${customer_name}/hr/dashboard/dynamic-dashboard`}
            name="Dynamic Dashboard"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/attendance-management/attendance-calendar`}
            name="Attendance Calendar"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/attendance-management/employee-attendance-list`}
            name="Employee Attendance List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/attendance-management/manage-fingerprint-attendance-data`}
            name="Manage Fingerprint Attendance Data"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/attendance-management/attendance-ratio`}
            name="Attendance Ratio"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/attendance-management/leave-balance-export`}
            name="Leave Balance Export"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/operation-request-for-attendance/announcement-list`}
            name="Announcement List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/operation-request-for-attendance/employee-leave-detail-information`}
            name="Employee Leave Detail"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/operation-request-for-attendance/employee-leave-request`}
            name="Emplyee Leave Request"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/operation-request-for-attendance/employee-leave-list`}
            name="Employee Leave List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/operation-request-for-attendance/work-type-request-detail`}
            name="Work Type Request Detail"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/operation-request-for-attendance/forget-card-request`}
            name="Forget Card Request"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/operation-request-for-attendance/forget-card-detail-information`}
            name="Forget Card Detail"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/operation-request-for-attendance/forget-card-list`}
            name="Forget Card List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/operation-request-for-attendance/allowance-request`}
            name="Allowance Request"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/operation-request-for-attendance/allowance-request-detail-information`}
            name="Allowance Request Detail"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/operation-request-for-attendance/allowance-request-list`}
            name="Allowance Request List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/operation-request-for-attendance/employee-shift-assign-entry`}
            name="Employee Shift Assign Entry"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/operation-request-for-attendance/employee-shift-assign-list`}
            name="Employee Shift Assign List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/operation-request-for-attendance/individual-working-hour-check`}
            name="Individual Working Hour Check"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/operation-request-for-attendance/checkin-checkout-request`}
            name="Check In Check Out Request"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/operation-request-for-attendance/checkin-checkout-list`}
            name="Check In Check Out List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/operation-request-for-attendance/checkin-checkout-detail-information`}
            name="Check In Check Out Detail Information"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/operation-request-for-attendance/presence-check-list`}
            name="Presence Check List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/operation-request-for-attendance/presence-check-detail-information`}
            name="Presence Check Detail Information"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/operation-request-for-attendance/exchange-date-request`}
            name="Exchange Date Request"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/operation-request-for-attendance/exchange-date-request-list`}
            name="Exchange Date Request List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/operation-request-for-attendance/exchange-date-request-detail`}
            name="Exchange Date Request Detail"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/operation-request-for-attendance/work-type-request`}
            name="Work Type Request"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/operation-request-for-attendance/work-type-request-list`}
            name="Work Type Request List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/operation-request-for-salary/individual-tax-calculation-list`}
            name="Individual Tax Calculation List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/operation-request-for-salary/after-overtime-request`}
            name="After Overtime Request"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/operation-request-for-salary/after-overtime-request-by-department`}
            name="After Overtime Request By Department"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/operation-request-for-salary/after-overtime-request-detail-information`}
            name="After Overtime Request Detail"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/operation-request-for-salary/after-overtime-request-list`}
            name="After Overtime Request List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/operation-request-for-salary/after-overtime-import`}
            name="After Overtime Import"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/operation-request-for-salary/before-overtime-request`}
            name="Before Overtime Request"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/operation-request-for-salary/before-overtime-request-list`}
            name="Before Overtime Request List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/operation-request-for-salary/before-overtime-request-detail-information`}
            name="Before Overtime Request Detail"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/operation-request-for-salary/employee-deduction-registration`}
            name="Employee Deduction Request"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/operation-request-for-salary/employee-deduction-registration-list`}
            name="Employee Deduction Request List"
            render={(props) => <HRMainLayout {...props} />}
          />{" "}
          <Route
            exact
            path={`/${customer_name}/hr/operation-request-for-salary/advanced-salary-request-list`}
            name="Advanced Salary Request List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/operation-request-for-salary/advanced-salary-request`}
            name="Advanced Salary Request"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/operation-request-for-salary/advanced-salary-request-detail-information`}
            name="Advanced Salary Request Detail Information"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/operation-request-for-salary/advanced-salary-request-list`}
            name="Advanced Salary Request List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/operation-request-for-salary/foreigner-income-tax-calculator`}
            name="Foreigner Income Tax Calculator"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/salary-calculation/yearly-bonus-registration`}
            name="Yearly Bonus Registration"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/salary-calculation/yearly-bonus-registration-list`}
            name="Yearly Bonus Registration"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/salary-calculation/individual-daily-deduction`}
            name="Individual Daily Deduction"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/salary-calculation/salary-calculation-step1`}
            name="Salary Calculation Step1"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/salary-calculation/salary-calculation-step2`}
            name="Salary Calculation Step2"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/salary-calculation/salary-calculation-step3`}
            name="Salary Calculation Step3"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/salary-calculation/bank-salary-pay`}
            name="Bank Salary Pay"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/salary-calculation/confirm-salary-calculation`}
            name="Confirm Salary Calculation"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/salary-calculation/payslip-mail-send`}
            name="Pay Slip Mail Send"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/salary-calculation/bonus-payslip-mail-send`}
            name="Bonus Pay Slip Mail Send"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/salary-calculation/bank-salary-error-check-list`}
            name="Bank Salary Error Check List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/salary-calculation/bonus-bank-salary-pay`}
            name="Bonus Bank Salary Pay"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/salary-calculation/export-calculated-salary-data`}
            name="ExportCalculatedSalaryData"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/salary-calculation/receivable-list`}
            name="Receivable List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/salary-calculation/yearly-bonus-registration`}
            name="Yearly Bonus Registration"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/salary-calculation/annual-leave-refund`}
            name="Annual Leave Refund"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/salary-calculation/annual-leave-refund-list`}
            name="Annual Leave Refund List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/report/total-ssb-download`}
            name="Total SSB Download"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/report/employee-leave-absent-list`}
            name="Employee Leave Absent List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/report/budget-year-income-tax-salary`}
            name="Budget Year Income Tax Salary"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/report/report-for-transfer-salary-pay`}
            name="Report For Transfer Salary Pay"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/report/report-for-payroll`}
            name="Report For Payroll"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/report/report-for-attendance`}
            name="Report For Attendance"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/report/employee-late-early-leave-list`}
            name="Employee Late Early Leave List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/report/income-tax-export`}
            name="Income Tax Export"
            render={(props) => <HRMainLayout {...props} />}
          />
          {/* LOAN MANAGEMENT */}
          <Route
            exact
            path={`/${customer_name}/hr/loan-management/dashboard`}
            name="Dashboard"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/loan-management/loan-registration`}
            name="Loan Registration"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/loan-management/loan-list`}
            name="Loan List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/loan-management/employee-loan-setting-list`}
            name="Employee Loan Setting List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/loan-management/loan-request-list`}
            name="Loan Request List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/loan-management/loan-request-detail-information`}
            name="Loan Request Detail Information"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/loan-management/loan-status-list`}
            name="Loan Status List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/loan-management/loan-request`}
            name="Loan Request"
            render={(props) => <HRMainLayout {...props} />}
          />

          {/* LOAN MANAGEMENT end */}

          <Route
            exact
            path={`/${customer_name}/hr/report/report-for-summary`}
            name="Income Tax Export"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/report/report-for-summary`}
            name="Income Tax Export"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/expense/business-trip-list`}
            name="Business Trip List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/expense/business-trip-request`}
            name="Business Trip Request"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/expense/business-trip-adjustment-detail`}
            name="Business Trip Adjustment Detail"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/expense/business-trip-adjustment-request`}
            name="Business Trip Adjustment Request"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/expense/summarize-total-amount-prepare`}
            name="Summarize Total Amount Prepare"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/expense/summarize-total-amount-prepare-list`}
            name="Summarize Total Amount Prepare List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/expense/expense-bank-payment-transaction`}
            name="Expense Bank Payment Transaction"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/expense/expense-bank-error-check-list`}
            name="Expense Bank Error Check List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/expense/report-for-bank-transfer-of-expense`}
            name="Report For Bank Transfer Of Expense"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/expense/expense-detail`}
            name="Expense Detail"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/expense/business-trip-detail`}
            name="Business Trip Detail"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/expense/expense-request`}
            name="Expense Request"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/expense/expense-list`}
            name="Expense List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/expense/expense-adjustment-request`}
            name="Expense Adjustment Request"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/expense/expense-adjustment-request-detail`}
            name="Expense Adjustment Detail"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/expense/dashboard`}
            name="Dashboard"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/plan/asset-management/asset-registration`}
            name="Asset Registration"
            render={(props) => <PlanManagementMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/plan/asset-management/asset-list`}
            name="Asset List"
            render={(props) => <PlanManagementMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/plan/plan-management/register-plan`}
            name="Register Plan"
            render={(props) => <PlanManagementMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/plan/plan-management/plan-calendar`}
            name="Plan Calendar"
            render={(props) => <PlanManagementMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/plan/erp`}
            name="ERP"
            render={(props) => <PlanManagementMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/plan/setting/role-and-permission-registration`}
            name="Role And Permission Registration"
            render={(props) => <PlanManagementMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/plan/setting/employee-role-registration`}
            name="Employee Role Registration"
            render={(props) => <PlanManagementMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/ticket-target-management/step-registration`}
            name="Step Registration"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/ticket-target-management/ticket-calculation`}
            name="Ticket Calculation"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/ticket-target-management/ticket-step-by-step-calculation`}
            name="Ticket Step By Step Calculation"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/ticket-target-management/ticket-calculation-list`}
            name="Ticket Calculation List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/ticket-target-management/employee-target-entry`}
            name="Employee Target Entry"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/ticket-target-management/style-registration-list`}
            name="Style Registration List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/ticket-target-management/employee-assign-registration`}
            name="Employee Assign Registration"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/ticket-target-management/step-list`}
            name="Step List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/ticket-target-management/employee-assign-registration-list`}
            name="Employee Assign Registration List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/ticket-target-management/ticket-adjustment`}
            name="Ticket Adjustment"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/ticket-target-management/target-list`}
            name="Target List"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            path={`/${customer_name}/erp/view-storage`}
            name="View Storage"
            render={(props) => <ERPMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/erp/logout`}
            name="Logout"
            render={(props) => <ERPMainLayout {...props} />}
          />
          <Route
            exact
            path="/"
            name="SignIn"
            render={() => {
              window.location.href = `/${customer_name}/sign-in`;
            }}
          />
          <Route
            exact
            path={`/${customer_name}`}
            name="SignIn"
            render={() => {
              window.location.href = `/${customer_name}/sign-in`;
            }}
          />
          <Route
            exact
            path={`/${customer_name}/hr`}
            name="SignIn"
            render={() => {
              window.location.href = `/${customer_name}/sign-in`;
            }}
          />
          <Route
            exact
            path={`/${customer_name}/erp`}
            name="SignIn"
            render={() => {
              window.location.href = `/${customer_name}/sign-in`;
            }}
          />
          <Route
            exact
            path={`/${customer_name}/plan`}
            name="SignIn"
            render={() => {
              window.location.href = `/${customer_name}/sign-in`;
            }}
          />
          <Route
            exact
            path={`/${customer_name}/hr/expense/expense-report`}
            name="Expense Report"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/erp/role-and-permission-management/employee-has-role-and-permission-download`}
            name="Employee Has Role And Permission Download"
            render={(props) => <ERPMainLayout {...props} />}
          />
          <Route
            exact
            path={`/${customer_name}/hr/setting/annual-increment`}
            name="Annual Increment"
            render={(props) => <HRMainLayout {...props} />}
          />
          <Route path="*" render={() => <Page404 />} />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
};

export default App;
